import {
    AppMeta,
    FeatureFlagsData, QtagsProcessedData, QuickViewFeatureFlagExperimentResponseObject
} from '../../../../../common/utils/hydration';
import { manualTagDropper } from '../../../../../common/utils/experimentation/manual-tag-dropper';
import { getCartPageId } from '../../../utils/tracking-utils';


export const getDeviceAndMembershipQuickViewEP = (featureFlagsData?: FeatureFlagsData, appMeta?: AppMeta): QuickViewFeatureFlagExperimentResponseObject | undefined => {
    const { isGuest = false, isMobile = false } = appMeta || {};
    if (isGuest) {
        return isMobile
            ? featureFlagsData?.enableQuickViewMobileGuest
            : featureFlagsData?.enableQuickViewDesktopGuest;
    }
    return isMobile
        ? featureFlagsData?.enableQuickViewMobileMember
        : featureFlagsData?.enableQuickViewDesktopMember;
};


export const dropXTTagsForQuickView = (quickViewFeatureFlagExperimentResponseObject?: QuickViewFeatureFlagExperimentResponseObject, qtags?: QtagsProcessedData) => {
    const { treatmentId } = quickViewFeatureFlagExperimentResponseObject || {};
    if (treatmentId) {
        manualTagDropper(treatmentId, qtags, String(getCartPageId(true)));
    }
};

export const isInQuickViewTreatment = (quickViewFeatureFlagExperimentResponseObject?: QuickViewFeatureFlagExperimentResponseObject) =>
    quickViewFeatureFlagExperimentResponseObject?.ENABLE_CART_QUICK_VIEW === 'true';
